export default {
    state: {
        menu: [
            { product: true, title: 'Information Workflow', bgcolor: 'crisk' },
            { button: true, title: 'Create Project', href: '/pbc/project#add' },
            // {
            //   title: 'Dashboard', icon: 'mdi mdi-view-dashboard-outline', href: '/pbc/dashboard',
            // },
            {
              title: 'All Projects', icon: 'mdi mdi-format-list-group', href: '/pbc/project',
            },
          ],
    },
    mutations: {
    },
    actions: {
    },
    getters: {
        
    },
}